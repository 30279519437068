import {
  AppBar,
  Container,
  useMediaQuery
} from "@mui/material";
import React from "react";
import NavBarDesktop from "../componenets/NavBarDesktop";
import NavBarMobile from "../componenets/NavBarMobile";
import "./NavBar.scss";

function NavBar({ handleTextDownClickShowCode,handleTextDownClickreturnButton }) {

  const isMobile = useMediaQuery("(max-width:1200px)");

  return (
    <AppBar className="NavBarAppBar">
      <Container maxWidth="xl">
        {isMobile ? <NavBarMobile  handleTextDownClickShowCode={handleTextDownClickShowCode} handleTextDownClickreturnButton={handleTextDownClickreturnButton} /> 
        : <NavBarDesktop handleTextDownClickShowCode={handleTextDownClickShowCode} handleTextDownClickreturnButton={handleTextDownClickreturnButton} />}
      </Container>
    </AppBar>
  );
}

export default NavBar;
