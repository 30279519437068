import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Typewriter from "typewriter-effect";
import TowPhoneImages from "../../assets/images/TowPhoneImage.png";
import "./StartTheRace.scss";


import { Trans, useTranslation } from 'react-i18next';

function StartTheRace() {

  const { t } = useTranslation();
  return (
    <Container id="Home" className="StartTheRaceContainer">
      <Grid container>
        <Grid
          xxl={6}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className="StartTheRaceLeftContainer"
        >
  <span className="StartTheRaceTypewriter">
      <Typewriter
        onInit={(typewriter) => {
          const loopTypewriter = () => {
            typewriter
              .typeString(
                `<Typography id='text'>${t('StartTheRace.EarnMoreWithOur')}</Typography>`
              )
              .pauseFor(1000)
              .changeDeleteSpeed(0.1)
              .deleteChars(t('StartTheRace.EarnMoreWithOur').length)
              .typeString(
                `<Typography id='text'>${t('StartTheRace.JoinOurGlobal')}</Typography>`
              )
              .pauseFor(1000)
              .changeDeleteSpeed(0.1)
              .deleteChars(t('StartTheRace.JoinOurGlobal').length)
              .callFunction(() => {
                setTimeout(loopTypewriter, 0);
              })
              .start();
          };

          loopTypewriter();
        }}
      />
    </span>


          <Typography className="StartTheRacebigTextProgram popins-bold">
          <Trans i18nKey='StartTheRace.AffiliateProgram'/>
          </Typography>

          <Box mt={3}>
            
            <Typography display="inline" className="StartTheRacesmallText">
            <Trans i18nKey='StartTheRace.ReferOthersTo'/>
              <a   href="https://nabcasino.com/" className="StartTheRaceLink"  >
                <Typography display="inline"className="StartTheRacesmallTextLink" > 
                <Trans i18nKey='StartTheRace.nabcasino'/> </Typography>
              </a>
              <Trans i18nKey='StartTheRace.throughYourLinks'/>
            </Typography>

          </Box>
          <a href="https://affiliates.nabaffiliates.com/site/signup" className="NavBarLink">
          <Button className="StartTheRacebecomeAPartner">
          <Trans i18nKey='StartTheRace.BecomeAPartner'/>
          </Button>
          </a>
        </Grid>
        <Grid
          className="StartTheRaceRightContainer"
          xxl={6}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            display: {
              xs: "none !important",
              sm: "block !important",
              md: "block !important",
              lg: "block !important",
              xl: "block !important",
              xxl: "block !important",
            },
          }}
        >
          <img alt="StartTheRace" className="StartTheRaceTowPhoneImages" src={TowPhoneImages} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default StartTheRace;
