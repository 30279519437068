import { Box, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { testimonials } from '../../assets/data';
import Testimonialbox from './Testimonialbox';
import './Testimonials.scss';

function Testimonials() {
  const [visibleCount, setVisibleCount] = useState(3); // Initially show 3 testimonials

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 3); // Load 3 more testimonials when clicking "Show More"
  };

  return (
    <Box className="testimonials">
      <Typography variant="h3" className="TestimonialsTitle popins-bold">
        <Trans i18nKey="Testimonials.Title" />
      </Typography>
      <Typography className="TestimonialSubTitle" fontSize={20}>
        <Trans i18nKey="Testimonials.Subtitle1" />
        <span className="TestimonialSubTitleWight">
          <Trans i18nKey="Testimonials.SubtitleWhite" />
        </span>
        <Trans i18nKey="Testimonials.Subtitle2" />
      </Typography>

      <Box className="testimonials-container">
        {testimonials.slice(0, visibleCount).map((testimonial) => (
          <Testimonialbox
            key={testimonial.name}
            rating={testimonial.rating}
            description={
              testimonial.description ? (
                <p className="testimonial-description">
                  <Trans i18nKey={`Testimonials.${testimonial.description}`} />
                </p>
              ) : (
                <p className="testimonial-description"></p>
              )
            }
            link={testimonial.link}
            name={testimonial.name}
            img={testimonial.img}
          />
        ))}
      </Box>

      {visibleCount < testimonials.length && (
        <Button  variant="contained" className="show-more-button" onClick={handleShowMore}>
          <Trans i18nKey="Testimonials.ShowMore" />
        </Button>
      )}
    </Box>
  );
}

export default Testimonials;
