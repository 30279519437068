import React from 'react'
import { Typography } from '@mui/material'
function TermsAndConditionsDefinition({Title,subTitles}) {
  const [firstHalf, secondHalf] = Title.split(':');
  return (
    <Typography>
    
    <Typography>
        {/* Render first part of the title */}
        <span style={{ color: 'rgb(255, 255, 255)' }}>{firstHalf}  :</span> 
        {/* Render second part of the title with inline style */}
        <span style={{ color: 'rgba(255, 255, 255, 0.5)' }}>{secondHalf}</span>

      </Typography>
   
    {subTitles.map((subItem) => (
    
    <Typography  className='TermsAndConditionsParagraphParagraphe'>{`• ${subItem.text}`}</Typography>
   

    
    ))}
   
</Typography>
  )
}

export default TermsAndConditionsDefinition