// Footer.js
import {
  Box,
  Container,
  Grid,
  Typography
} from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import Logo from "../../assets/images/Partners-nab-logo.svg";
import "./Footer.scss";
function Footer({
  handleTextDownClickShowFooter,
  handleTextDownClickShowCode,
  handleTextDownClickremoveButton,
  handleTextDownClickreturnButton,
  showTermsButton,
  removeTermsButton,
}) {


  return (
    <Container className="FooterContainer">
      <Grid container className="FooterGrid">
        <Grid
          item
          xxl={4}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          className="BigWaletGridLeft"
          id="TermsOfCondition"
        >
          <a href="https://nabcasino.com/" className="FooterLink">
            <img src={Logo} className="FooterLogo" alt="Logo"></img>
          </a>
          <Typography className="AllRightReservedUp">
            © {new Date().getFullYear()}. <Trans i18nKey="Footer.AllRights" />
          </Typography>
        </Grid>

        <Grid item xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
          <Box className="BigWaletGridRight">
            <Typography flexGrow={0.8}></Typography>

            <Typography
              className="FooterText"
              mb={3}
              onClick={() => {
                handleTextDownClickShowCode();
                handleTextDownClickreturnButton();
              }}
              component="a"
              href={`#Home`}
              sx={{ display: showTermsButton }}
            >
              <Trans i18nKey="Footer.TextDownHome" />
            </Typography>

            <Typography
              className="FooterText"
              onClick={() => {
                handleTextDownClickShowCode();
                handleTextDownClickreturnButton();
              }}
              component="a"
              href={`#become-a-partner`}
              style={{ display: "block" }}
            >
              <Trans i18nKey="Footer.Textup" />
            </Typography>

            <Typography
              className="FooterTextMidle"
              onClick={() => {
                handleTextDownClickShowCode();
                handleTextDownClickreturnButton();
              }}
              component="a"
              href={`#contact-us`}
              style={{ display: "block" }}
            >
              <Trans i18nKey="Footer.TextMidle" />
            </Typography>

            <Typography
              className="FooterText"
              onClick={() => {
                handleTextDownClickShowFooter();
                handleTextDownClickremoveButton();
              }}
              component="a"
              href={`#Terms`}
              sx={{ display: removeTermsButton }}
            >
              <Trans i18nKey="Footer.TextDownTerms" />
            </Typography>
          </Box>

          <Typography className="AllRightReservedDown">
            © {new Date().getFullYear()}. <Trans i18nKey="Footer.AllRights" />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
