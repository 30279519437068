import React from "react";
import { useTranslation } from "react-i18next";
import { lngs } from "../../assets/data";
import "./DropDownLanguage.scss";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icon.css';
import { Dropdown } from 'react-bootstrap';

const DropDownLanguages = ({ mb, width , drawer,wordLength}) => {
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  
  return (
    <Dropdown className="LanguagesContainer" >
    <Dropdown.Toggle variant="primary" id="dropdown-basic" 
          onChange={(e) => handleChangeLanguage(e?.target?.value)}
      value={i18n?.language}
      style={{ width: width, marginBottom: mb,backgroundColor: 'transparent',border:'none'}}>
    <span className={t("Flag.iconClassName")}/><span> {i18n?.language}</span> 
    </Dropdown.Toggle>

    <Dropdown.Menu style={{padding: 0,width: "80px"}}>
        {lngs.map((lng) => {
      return (
      <Dropdown.Item active href="#" value={lng?.shortName} onClick={() => handleChangeLanguage(lng?.shortName)} className="LanguagesItem" >
        <span className={lng.iconClassName}> </span><span>{lng.shortName}</span>
      </Dropdown.Item>
      );
     })}
    </Dropdown.Menu>
  </Dropdown>
  );
};

export default DropDownLanguages;
