import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import DropDownLanguages from "../../Layout/componenets/DropDownLanguage";
import { NavBarSections } from "../../assets/data";
import Logo from "../../assets/images/Partners-nab-logo.svg";
import "../navBar/NavBar.scss";

function NavBarDesktop({ handleTextDownClickShowCode,handleTextDownClickreturnButton }) {
  const { i18n } = useTranslation();
  
  const ListItems = NavBarSections.map((section, index) => (
    <Typography
      className="NavBarWords"
      key={index}
      component="a"
      href={`#${section.id}`}
    
      

      onClick={() => {
        handleTextDownClickShowCode();
        handleTextDownClickreturnButton();
    
      }}
    >
      <Trans i18nKey={`NavBar.${section?.name}`} />
    </Typography>
  ));
  return (
    <Grid container>
      <Grid item xs={0} sm={0} md={0} lg={0.5} xl={1.5}></Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={11}
        xl={9}
        display={"flex"}
        alignItems="center"
      >
        <a href="https://nabcasino.com/" className="NavBarLink">
          <img src={Logo} className="NavBarLogo" alt="Logo" />
        </a>
        <Typography sx={{ flexGrow: 1 }} />

        {ListItems}

        <a href="http://affiliates.nabaffiliates.com/login" className="NavBarLink">
        <Button className="NavBarLogin">
        
          {} <Trans i18nKey="NavBar.LogIn" />{" "}
        </Button>
        </a>

        <a href="http://affiliates.nabaffiliates.com/site/signup" className="NavBarLink">
        <Button className="NavBarSignUp">
          {} <Trans i18nKey="NavBar.SignUp" />
        </Button>
        </a>
        <DropDownLanguages drawer={false} wordLength={ i18n?.language} />
      </Grid>
      <Grid item xs={0} sm={0} md={0} lg={0.5} xl={1.5}></Grid>
    </Grid>
  );
}

export default NavBarDesktop;
