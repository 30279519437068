import { Box, Typography } from '@mui/material'
import React from 'react'
import './BoxWithImage.scss';

function BoxWithImage({ src, title, text }) {
  return (
<Box className="primaryBox" sx={{ display: 'flex', padding: '10px' }}>
    <img alt="Box" src={src} className='imageStyle'/>
    <div style={{ marginLeft: '10px' }}>
        <Typography  className='boxTitle'>{title}</Typography>
        <Typography  className='boxText'>{text}</Typography>
    </div>
</Box>

  )
}

export default BoxWithImage