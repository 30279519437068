import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).use(initReactI18next)

  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      EN: {
        translation: {
          NavBar: {
            BecomeAPartner: "Become a partner",
            News: "News",
            Advantages: "Advantages",
            ContactUs: "Contact us",
            LogIn: "Log in",
            SignUp: "Sign up",
          },
          Flag: {
            iconClassName: "flag-icon flag-icon-us me-1",
          },
          StartTheRace: {
            EarnMoreWithOur: "Earn more with our",
            JoinOurGlobal: "Join our global",
            AffiliateProgram: " affiliate program",
            BecomeAPartner: " Become a partner",
            ReferOthersTo: "Refer others to ",
            nabcasino: "nabcasino.com ",
            throughYourLinks:
              " through your links, earn commissions on their registrations and play.",
          },
          BecomeAPartner: {
            titleGray: "Become",
            titleWhite: " a partner",
            TexteWhite: "Nabcasino.com  ",
            TexteGray:
              "is where you can enjoy competitive odds  and the best Bitcoin betting experience. Our team strives for a fulfilling, fun,  and fair betting journey, offering you a top-notch interactive platform.",
            EarnRevenuetitle: "Earn Revenue",
            EarnRevenuetext:
              "Earn up to 50% revenue share with no quotas as an affiliate!",
            GameOnTitle: "Game On",
            GameOntext:
              "Explore endless excitement with 5000+ games at our casino. From classics to new releases, we've got your gaming adventure covered!",
            GlobalReachTitle: "Global Reach",
            GlobalReachtext: ` Experience our platform's global accessibility, reaching users around the world with support for 16 languages. `,
          },
          Casino: {
            Title: "Casino royale",
            smallText:
              'Step into the world of glamour and chance in our premier <span class="SportsToPlaysmallTextwhite">Casino section!</span>',
            LiveCasinoTitle: "Live Casino",
            LiveCasinoText:
              "Engage with real dealers in our Live Casino. Real-time action for those who crave the thrill of the game floor.",
            CalssicslotsTitle: " Classic Slots",
            CalssicslotsText:
              "Spin the reels and feel the rush with classic slots. Timeless fun with a sprinkle of luck and nostalgia.",
            TableGamesTitle: "Table Games",
            TableGamesText:
              "Challenge the odds with a variety of table games. From blackjack to roulette, it’s your strategy against the house.",
          },

          SportsPlay: {
            Title: "Sports play",
            smallText:
              'Dive into the heart of the action with our <span class="SportsToPlaysmallTextwhite">Sports section</span> at your fingertips!',
            HorseRacingTitle: "Horse Racing",
            HorseRacingText:
              "Feel the excitement of horse racing—fast-paced action and thrilling moments await!",
            AussieRulesTitle: "Aussie Rules",
            AussieRulesText:
              "Enjoy Aussie Rules—high-flying plays and intense competition for non-stop fun!",
            FootballTitle: "Football",
            FootballText:
              "Kick into football action—goals, strategies, and excitement for all fans!",
          },

          Advantages: {
            Title: "Advantages",
            smallText:
              'Team up with us and get up to <span class="SportsToPlaysmallTextwhite">50% in Revenue share</span> -its that simple',
            DetailedStatsTitle: "Detailed Stats",
            DetailedStatsText:
              "Track campaigns, customers, and traffic in real-time with our efficient affiliate system.",
            LifetimeRevenueTitle: "Lifetime Revenue",
            LifetimeRevenueText:
              "Unlock limitless earnings with increased traffic. Our generous payout ensures you earn from every player you attract.",
            FastAffiliatePaymentsTitle: "Fast Affiliate Payments",
            FastAffiliatePaymentsText:
              "Count on prompt payments—commissions processed and paid within the first five business days every month for our affiliates.",
          },

          ContactUs: {
            Title:
              'Contact <span class="SportsToPlaysmallTextwhite popins-bold">our affiliate team</span> ',
            smallText:
              'Feel free to <span class="ContactUsFeelFreewhite popins-bold">  contact us </span>  any time. We will get back to you as soon as we can.',
            SendUsMessage: "Send us message",
            iHaveReadAndAgreed:
              ' I have read and agree to the <span class="ContactUsiHaveReadAndAgreedUnderline ">  terms of service  </span> and  <span class="ContactUsiHaveReadAndAgreedUnderline ">privacy</span>',
            SendRequest: "Send request",
            YourEmail: "YOUR E-MAIL*",
            MessageText: "MESSAGE TEXT*",
          },

          Footer: {
            Textup: "Become a partner",
            TextMidle: "Contact us",
            TextDownTerms: "Terms of Conditions",
            TextDownHome: "Home",
            AllRights: "All Rights Reserved, New Age N.V",
          },

          Events: {
            Title: "Events",
            SubtitleWhite: "Join us",
            Subtitle: "for upcoming events and stay connected!",
          },

          Testimonials: {
            Title: "Testimonials",
            Subtitle1: "See how our affiliates are",
            SubtitleWhite: " succeeding ",
            Subtitle2: "with us!",

   
            description1:"Partnering with NAB Affiliates has been excellent. Their crypto offerings enhance our site, and the team is always helpful. Adding nabcasino.com was easy due to their innovation and strong leadership. We look forward to continued success with such a reliable affiliate program.",
      
            description2:"From the beginning, working with Nab Affiliates has been a pleasure. Our account manager has established a great rapport with us and has been very professional and responsive to our needs. We wholeheartedly advise working with  Nab Affiliates!",  
           
            description3:"For The Gambler Bay, partnering with NAB AFFILIATES is a very important step. We have long been striving for this and believe that this is a mutually beneficial investment of forces. NAB AFFILIATES is the embodiment of functions that are absolutely necessary for modern affiliate programs. Playing for real money in online casinos is an important step, and you fully support the customers of your casinos!",
      
            description4:"Nab Affiliates continues to set the standard in their industry. Their dedication to achieving top-tier results and their proactive approach make them an irreplaceable asset. We wholeheartedly endorse their services.",
        
            description5:"Affiliate marketing has evolved into a powerful communication strategy for our organization, creating new opportunities for growth and boosting profits. By partnering with a dependable affiliate, NABCASINO, we have been able to broaden our reach substantially and strengthen customer loyalty by endorsing services that genuinely address their requirements. ",
   
            description6:"Working with professionals is fun. They have a reputable casino with reasonable affiliate partnerships in numerous nations. Strongly advise Nab Affiliates.",
    
            description7:"Working with NAB AFFILIATES has been a fantastic experience. Their affiliate program offers an excellent commission structure, and their team is always ready to assist and provide unwavering support. Highly recommended by spicycasinos.com",
           
            description8:"CasinoMentor just started our journey with Nabcasino. Our manager was very straight, reliable and helped to set everything up on the highest level. We're looking forward to grow our partnership.",
            description9:"  NAB Affiliates is one of our main partners, and rest assured we can recommend them as a reliable and trusted affiliate program for webmasters. Great communication, great brand to promote, lot of accepted geo's. You'll have great results with NAB",
       

            ShowMore:"Show more"
          },



         },
      },
    

    FR: {
      translation: {
        NavBar: {
          BecomeAPartner: "Devenir partenaire",
          News: "Actualités",
          Advantages: "Avantages",
          ContactUs: "Contactez-nous",
          LogIn: "connecter",
          SignUp: "S'inscrire",
        },
        Flag: {
          iconClassName: "flag-icon flag-icon-fr me-1",
        },
        StartTheRace: {
          EarnMoreWithOur: "Gagnez plus avec notre",
          JoinOurGlobal: "Rejoignez notre mondial",
          AffiliateProgram: " Programe affiliation",
          BecomeAPartner: " Devenez partenaire",
          ReferOthersTo: "Référez d'autres à",
          nabcasino: " nabcasino.com ",
          throughYourLinks:
            "via vos liens, gagnez des commissions sur leurs inscriptions et leurs jeux.",
        },

        BecomeAPartner: {
          titleGray: "Devenir",
          titleWhite: "partenaire",
          TexteWhite: "Nabcasino.com ",
          TexteGray:
            "est l'endroit où vous pouvez profiter de cotes compétitives et de la meilleure expérience de paris Bitcoin. Notre équipe s'efforce de vous offrir un parcours de paris enrichissant, amusant et équitable, en vous proposant une plateforme interactive de premier ordre.",
          EarnRevenuetitle: "Gagner des revenus",
          EarnRevenuetext:
            "Gagnez jusqu'à 50% de part de revenus sans quota en tant qu'affilié!",
          GameOnTitle: "C'est parti",
          GameOntext:
            "Explorez une excitation sans fin avec plus de 5000 jeux dans notre casino. Des classiques aux nouveautés, nous couvrons votre aventure de jeu!",
          GlobalReachTitle: "Portée mondiale",
          GlobalReachtext:
            "Découvrez l'accessibilité mondiale de notre plateforme, atteignant des utilisateurs du monde entier avec prise en charge de 16 langues.",
        },

        Casino: {
          Title: "Casino royale",
          smallText:
            'Entrez dans le monde du glamour et du hasard dans notre section de premier <span class="SportsToPlaysmallTextwhite">Casino!</span>',
          LiveCasinoTitle: "Casino en direct",
          LiveCasinoText:
            "Interagissez avec de vrais croupiers dans notre casino en direct. Action en temps réel pour ceux qui recherchent le frisson du casino.",
          CalssicslotsTitle: "Machines à sous classiques",
          CalssicslotsText:
            "Faites tourner les rouleaux et ressentez l'excitation des machines à sous classiques. Divertissement intemporel avec une touche de chance et de nostalgie.",
          TableGamesTitle: "Jeux de table",
          TableGamesText:
            "Défiez les probabilités avec une variété de jeux de table. Du blackjack à la roulette, c'est votre stratégie contre la maison.",
        },

        SportsPlay: {
          Title: "Jeux de sport",
          smallText:
            'Plongez au cœur de l\'action avec notre section <span class="SportsToPlaysmallTextwhite">Sports</span> à portée de main!',
          HorseRacingTitle: "Courses de chevaux",
          HorseRacingText:
            "Ressentez l'excitation des courses de chevaux - une action rapide et des moments palpitants vous attendent!",
          AussieRulesTitle: "Règles australiennes",
          AussieRulesText:
            "Profitez des règles australiennes - des jeux aériens et une compétition intense pour un plaisir sans fin!",
          FootballTitle: "Football",
          FootballText:
            "Plongez dans l'action du football - buts, stratégies et excitement pour tous les fans!",
        },

        Advantages: {
          Title: "Avantages",
          smallText:
            'Faites équipe avec nous et obtenez jusqu\'à <span class="SportsToPlaysmallTextwhite">50% de part de revenus</span> - aussi simple que cela',
          DetailedStatsTitle: "Statistiques détaillées",
          DetailedStatsText:
            "Suivez les campagnes, les clients et le trafic en temps réel avec notre système d'affiliation efficace.",
          LifetimeRevenueTitle: "Revenu à vie",
          LifetimeRevenueText:
            "Débloquez des gains illimités avec une augmentation du trafic. Notre rémunération généreuse garantit que vous gagnez sur chaque joueur que vous attirez.",
          FastAffiliatePaymentsTitle: "Paiements d'affiliation rapides",
          FastAffiliatePaymentsText:
            "Comptez sur des paiements rapides - commissions traitées et payées dans les cinq premiers jours ouvrables de chaque mois pour nos affiliés.",
        },
        Testimonials: {
          Title: "Témoignages",
          Subtitle1: "Voyez comment nos affiliés",
          SubtitleWhite: " réussissent ",
          Subtitle2: "avec nous !",
        
          description1: "Collaborer avec les affiliés de NAB a été excellent. Leurs offres de cryptomonnaie enrichissent notre site, et l'équipe est toujours utile. L'ajout de nabcasino.com a été facile grâce à leur innovation et leur solide leadership. Nous attendons avec impatience de continuer à réussir avec un programme d'affiliation aussi fiable.",

          description2:"From the beginning, working with Nab Affiliates has been a pleasure. Our account manager has established a great rapport with us and has been very professional and responsive to our needs. We wholeheartedly advise working with  Nab Affiliates!",
 
          description3:"Pour The Gambler Bay, s'associer à NAB AFFILIATES est une étape très importante. Nous avons longtemps œuvré pour cela et nous croyons que c'est un investissement mutuellement bénéfique de nos efforts. NAB AFFILIATES incarne des fonctionnalités absolument indispensables pour les programmes d'affiliation modernes. Jouer pour de l'argent réel dans les casinos en ligne est une étape importante, et vous soutenez pleinement les clients de vos casinos!",

          description4:"Nab Affiliates continue de définir les normes dans leur secteur. Leur dévouement à obtenir des résultats de premier ordre et leur approche proactive en font un atout irremplaçable. Nous recommandons vivement leurs services.",
            
          description5:"Le marketing d'affiliation est devenu une stratégie de communication puissante pour notre organisation, créant de nouvelles opportunités de croissance et augmentant les bénéfices. En collaborant avec un affilié fiable, NABCASINO, nous avons considérablement élargi notre portée et renforcé la fidélité des clients en recommandant des services qui répondent véritablement à leurs besoins.",
        
          description6:"Travailler avec des professionnels est agréable. Ils possèdent un casino réputé avec des partenariats d'affiliation intéressants dans de nombreux pays. Je recommande vivement Nab Affiliates.",     
    
          description7:"Travailler avec NAB AFFILIATES a été une expérience fantastique. Leur programme d'affiliation offre une excellente structure de commission, et leur équipe est toujours prête à aider et à offrir un soutien indéfectible. Fortement recommandé par spicycasinos.com.",
       
          description8:"CasinoMentor vient juste de commencer notre aventure avec Nabcasino. Notre gestionnaire a été très direct, fiable et nous a aidés à tout mettre en place au plus haut niveau. Nous sommes impatients de développer notre partenariat",
       
          description9:"NAB Affiliates est l'un de nos principaux partenaires, et soyez assurés que nous pouvons les recommander comme un programme d'affiliation fiable et de confiance pour les webmasters. Excellente communication, excellente marque à promouvoir, avec de nombreux territoires acceptés. Vous obtiendrez d'excellents résultats avec NAB.",
       
         ShowMore:"Voir plus"

        }, 

       
       
         Events: {
          Title: "Événements",
          SubtitleWhite: "Rejoignez-nous",
          Subtitle: "pour les événements à venir et restez connectés !",
        },
        
        
        ContactUs: {
          Title:
            'Contactez <span class="SportsToPlaysmallTextwhite popins-bold"> notre équipe d\'affiliation</span> ',
          smallText:
            'N\'hésitez pas à <span class="ContactUsFeelFreewhite popins-bold"> nous contacter </span>  à tout moment. Nous vous répondrons dès que possible.',
          SendUsMessage: "Envoyez-nous un message",
          iHaveReadAndAgreed:
            " J'ai lu et accepté les <span class=\"ContactUsiHaveReadAndAgreedUnderline popins-bold\"> conditions d'utilisation et la politique de confidentialité </span>",
          SendRequest: "Envoyer demande",
          YourEmail: "VOTRE E-MAIL*",
          MessageText: "TEXTE DU MESSAGE*",
        },
        Footer: {
          Textup: "Devenir partenaire",
          TextMidle: "Contactez-nous",
          TextDown: "Termes et Conditions",
          AllRights: "Tous droits réservés, Nouveau Age N.V",
          TextDownTerms:"Termes et Conditions"
        },
      },
      },
    },
  });

export default i18n;
