import {
  Box,
  Button,
  List,
  Typography,
} from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { NavBarSections } from "../../assets/data";
import XButton from "../../assets/images/close menu x.svg";
import "../navBar/NavBar.scss";
import DropDownLanguages from "./DropDownLanguage";
function NavBarDrawerList({ open, setOpen = () => {}, handleTextDownClickreturnButton, handleTextDownClickShowCode }) {
 

  const ListItems = NavBarSections.map((section, index) => (
    <Typography
      className="NavBarWordsDrawer"
      key={index}
      component="a"
      href={`#${section.id}`}
      onClick={() => {
        setOpen(!open); 
        handleTextDownClickShowCode(); 
        handleTextDownClickreturnButton();
      }}
    >
       <Trans i18nKey={`NavBar.${section?.name}`} />
    </Typography>
  ));

  return (
    <Box className="NavBarDrawerContainer" >
      <div style={{display:'flex', alignItems:'center',marginBottom:'48px'}}>
      <img
      alt="NavBAr"
        src={XButton}
        className="NavBarXButton"
        onClick={() => setOpen(!open)}
      />  <Typography sx={{ flexGrow: 0.9 }} /> <DropDownLanguages width={"80px"} drawer={true} /></div>
      <List sx={{ padding: "0" }}>{ListItems}</List>
      <div className="NavBarButtonContainer">

      <a href="https://affiliates.nabaffiliates.com/login" className="NavBarLink" style={{textDecoration:'none'}} >
        <Button onClick={() => setOpen(!open)} className="NavBarDrawerLogin">
          {" "}
          <Trans i18nKey="NavBar.LogIn" />
        </Button>
</a>

        <a href="https://affiliates.nabaffiliates.com/site/signup" className="NavBarLink">
        <Button onClick={() => setOpen(!open)} className="NavBarDrawerSignUp">
          {" "}
          <Trans i18nKey="NavBar.SignUp" />
        </Button>
        </a>

      </div>
    
      <Typography className="AllRightReserved">
        © {new Date().getFullYear()}. <Trans i18nKey="Footer.AllRights" />
      </Typography>
    </Box>
  );
}

export default NavBarDrawerList;
