import React from 'react';
import './Events.scss';
import { Box, Grid, Typography } from '@mui/material';
import { Events } from '../../assets/data'; 
import flesh from '../../assets/logos/flesh.svg';
import { Trans } from 'react-i18next';

const EventsComponent = () => {

    return (
        <Box className="events-container">
            <Typography variant='h3' className='page-title popins-bold'>
            <Trans i18nKey="Events.Title" />
            </Typography>
            <Typography variant='h6' className='EventSubTitle' pb={5}>
                <span className='EventSubTitleWhite'><Trans i18nKey="Events.SubtitleWhite"/> </span> <Trans i18nKey="Events.Subtitle"/>
            </Typography>
            <Box className='container'>
                <Grid container spacing={5} className='EventsBox'>
                    {Events.map((event, index) => (
                        <Grid item md={12} lg={index < 2 ? 6 : 4} xs={12} sm={12} key={index}>
                            {/* <a href={event.href} className={`event-box ${index < 2 ? 'big-box' : 'small-box'}`}> */}
                            <span className={`event-box ${index < 2 ? 'big-box' : 'small-box'}`}>
                                <Box className='image-container'>
                                    <img src={event.imageSrc} alt={event.imageAlt} className='image' />
                                </Box>
                                <Box className={`text-overlay ${index < 2 ? 'text-overlay-l' : ''}`}>
                                    <Box className='logo-container'>
                                        <img src={event.logoSrc} alt={event.logoAlt} className='logo' />
                                    </Box>
                                    <Box className='text-content'>
                                        <Typography variant='h8' fontSize={18} className='text-overlay-info'>{event.title}</Typography>
                                        <Typography>{event.location}</Typography>
                                        <Typography variant='h5' className='popins-bold date-text'>
                                            {event.date}
                                           
                                        </Typography>
                                    </Box>
                                </Box>
                            {/* </a> */}
                            </span>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default EventsComponent;
