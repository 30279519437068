import React from "react";

import { Box, Container, Grid, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import BoxWithImage from "../../Layout/componenets/BoxWithImage.js";
import { CasinoTextBoxArray } from '../../assets/data';
function Casino() {
 



  const CasinoListOfBoxs = CasinoTextBoxArray.map((CasinoTextBoxArray, index) => (
    <Grid item xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
      <BoxWithImage
        className="SportsToPlayBoxWithImage"
        key={index}
        src={CasinoTextBoxArray.src}
        text={<Trans i18nKey={`Casino.${CasinoTextBoxArray?.text}`} />}
        title={<Trans i18nKey={`Casino.${CasinoTextBoxArray?.title}`} />}
      />
    </Grid>
  ));
  return (
    <Container id="news" className="SportsToPlayContainer" >
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Typography className="SportsToPlayTitle popins-bold" id="Advantaged">
          {" "}
          <Trans i18nKey="Casino.Title" />
        </Typography>

        <Typography className="SportsToPlaysmallText SportsToPlaysmallTextContainer">
          <Trans
            i18nKey="Casino.smallText"
            components={{
              span: <span className="SportsToPlaysmallTextwhite" />,
            }}
          />
        </Typography>

        <Grid container>{CasinoListOfBoxs}</Grid>
      </Box>
      <div className="Divider" />

    </Container>
  );
}

export default Casino;
