import {
    Grid,Drawer
  } from '@mui/material'
  import React, { useState } from 'react'
  import '../navBar/NavBar.scss';
  import Logo from "../../assets/images/Partners-nab-logo.svg";
import DrawerList from './NavBarDrawerList';
import humberg from '../../assets/images/hamberg.svg';
import { useWindowSize } from '../../assets/data';
function NavBarMobile({ handleTextDownClickShowCode,handleTextDownClickreturnButton}) {

  const { xsColumnSize } = useWindowSize();
    const [open, setOpen] = useState(false);

  return (
    <Grid container alignItems="center">
      <Grid item xs={xsColumnSize}>
        <img
          src={humberg}
          fontSize="small"
          onClick={() => setOpen(true)}
          className="NavBarHumberg"
          alt="QU"
        />
   
        <Drawer open={open} onClose={() => setOpen(false)}>
          <DrawerList open={open} setOpen={setOpen} handleTextDownClickShowCode={handleTextDownClickShowCode} handleTextDownClickreturnButton={handleTextDownClickreturnButton}/>
        </Drawer>
      </Grid>

      <Grid item>
        <a href="https://nabcasino.com/" className="NavBarLink">
          <img src={Logo} alt="UU" className="NavBarLogo" />
        </a>
      </Grid>
    </Grid>
  );
}

export default NavBarMobile