import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { BecomeAPartnerTextBoxArray } from "../../assets/data";
import BoxWithImage from "../../Layout/componenets/BoxWithImage.js";
import "./BecomeApartner.scss";
function BecomeApartner() {

  const BecomeAPartnerListOfBoxs = BecomeAPartnerTextBoxArray.map(
    (BecomeAPartnerTextBoxArray, index) => (
      <BoxWithImage
        key={index}
        src={BecomeAPartnerTextBoxArray.src}
        text={<Trans i18nKey={`BecomeAPartner.${BecomeAPartnerTextBoxArray?.text}`} />}
        title={<Trans i18nKey={`BecomeAPartner.${BecomeAPartnerTextBoxArray?.title}`} />}
      />
    )
  );

  return (
    <Container id="become-a-partner" className="BecomeAPartnerContainer">
      <Grid container>
        <Grid
          xxl={6}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className="BecomeAPartnerSticky"
          pr={4}
        >
          <div className="BecomeAPartnerTitleContainer">
            <Typography
              display={"inline"}
              className="BecomeAPartnerTitleGray popins-bold"
            >
              {" "}
              <Trans i18nKey="BecomeAPartner.titleGray" />{" "}
            </Typography>
            <Typography
              display={"inline"}
              className="BecomeAPartnerTitleWhite popins-bold"
            >
              {" "}
              <Trans i18nKey="BecomeAPartner.titleWhite" />{" "}
            </Typography>
          </div>
          <div>
            <a href="https://nabcasino.com/" className="FooterLink">
              <Typography
                display={"inline"}
                className="BecomeAPartnerTexteWhite"
              >
                <Trans i18nKey="BecomeAPartner.TexteWhite" />
              </Typography>
            </a>
            <Typography display={"inline"} className="BecomeAPartnerTexteGray">
              <Trans i18nKey="BecomeAPartner.TexteGray" />
            </Typography>
          </div>
        </Grid>
        <Grid xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} mb={3}>
          {BecomeAPartnerListOfBoxs}
        </Grid>
      </Grid>
    </Container>
  );
}

export default BecomeApartner;
