import http from "./http-common";


const sendContactusEmail = async (data) => {
    try {
      return http().post(`/nabaffiliates/send-mail`, data);
    } catch (error) {
      console.log("send mail error", error);
    }
  };

  export const api = {
    sendContactusEmail,
  }