import {
  Typography,
  Grid,
  Button,
  Container,
  Input,
  TextField,
  Checkbox,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import "./ContactUs.scss";

import { useTranslation, Trans } from "react-i18next";
import { api } from "../../api/api";
import { CheckCircle } from "@mui/icons-material";

function ContactUs({
  showTerms,
  handleTextDownClickShowFooter,
  handleTextDownClickShowCode,
  handleTextDownClickremoveButton,
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [tosCheckBox, setTosCheckBox] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const sendMail = await api.sendContactusEmail({ email, message, tosCheckBox });
      if (sendMail.data.statusCode === 201) {
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      }
      setEmail("");
      setMessage("");
      setTosCheckBox(false);
    } catch (error) {
      console.log("send mail error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container id="contact-us" className="ContactUsContainer">
      {success &&
        <Alert icon={<CheckCircle fontSize="inherit" />} severity="success">
          Message sent successfully
        </Alert>
      }
      <Grid container>
        <Grid
          item
          xxl={6}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className="ContactUsleftGridContainer"
        >
          <Typography className="ContactUsTitleContainer ContactUsTitleWitheColor  popins-bold">
            <Trans
              i18nKey="ContactUs.Title"
              components={{
                span: <span className="ContactUsTitle popins-bold" />,
              }}
            />
          </Typography>

          <Typography className="ContactUssmallTextContainer ContactUsFeelFree">
            <Trans
              i18nKey="ContactUs.smallText"
              components={{ span: <span className="ContactUsFeelFreewhite" /> }}
            />
          </Typography>
        </Grid>

        <Grid item xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
          <Typography className="ContactUsSendUsMessage">
            <Trans i18nKey="ContactUs.SendUsMessage" />
          </Typography>
          <Input
            type="email"
            required
            disableUnderline={true}
            placeholder={t("ContactUs.YourEmail")}
            className="ContactUsInputTextField"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            sx={{
              "& fieldset": { border: "none" },
            }}
            inputProps={{ style: { color: "#82808F" } }}
            className="ContactUsTextAreaOfContactUs"
            placeholder={t("ContactUs.MessageText")}
            multiline
            rows={8}
            maxRows={8}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              sx={{
                color: "#E05E5E",
                "&.Mui-checked": {
                  color: "#E05E5E",
                },
              }}
              value={tosCheckBox}
              checked={tosCheckBox}
              onChange={(e) => setTosCheckBox(e.target.checked)}
            />

            <Typography className="ContactUsiHaveReadAndAgreed">
              <Trans
                i18nKey="ContactUs.iHaveReadAndAgreed"
                components={{
                  span: (
                    <Typography
                      className="ContactUsiHaveReadAndAgreedUnderline"
                      component="a"
                      href={`#Terms`}
                      onClick={() => {
                        handleTextDownClickShowFooter();
                        handleTextDownClickremoveButton();
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          </div>


          <Button
            className="ContactUsSendRequest"
            disabled={tosCheckBox === false || email === "" || message === ""}
            onClick={handleSubmit}
          >
            {!loading ? <Trans i18nKey="ContactUs.SendRequest" /> : <CircularProgress sx={{ color: 'black' }} />}
          </Button>

        </Grid>
      </Grid>
    </Container>
  );
}

export default ContactUs;
