import { Typography } from "@mui/material";
import { React } from "react";
function TermsAndConditionsParagraph({ Title, subTitles, counter }) {
  return (
    <Typography>
      <Typography className="TermsAndConditionsParagraphTitle">
        {counter + "." + Title}
      </Typography>

      {subTitles.map((subItem, subIndex) => {
        return (
          <>
            <Typography className="TermsAndConditionsParagraphParagraphe">
              {counter + `.${subIndex + 1} ${subItem.text}`}
            </Typography>

            {subItem?.subtext !== undefined && (
              <>
                <ol style={{ listStyleType: "lower-alpha" }}>
                  {subItem.subtext.map((subItem) => (
                    <li className="TermsAndConditionsParagraphParagraphe">
                      {subItem.text}
                    </li>
                  ))}
                </ol>
              </>
            )}
          </>
        );
      })}
    </Typography>
  );
}

export default TermsAndConditionsParagraph;
