import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import "./Advantages.scss";

import { Trans } from "react-i18next";
import { AdvantagesTextBoxArray } from "../../assets/data";
import BoxWithImage from "../componenets/BoxWithImage";

function Advantages() {



  const AdvantagesListOfBoxs = AdvantagesTextBoxArray.map((AdvantagesTextBoxArray, index) => (
    <Grid item xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
      <BoxWithImage
        className="SportsToPlayBoxWithImage"
        key={index}
        src={AdvantagesTextBoxArray.src}
        text={<Trans i18nKey={`Advantages.${AdvantagesTextBoxArray?.text}`} />}
        title={<Trans i18nKey={`Advantages.${AdvantagesTextBoxArray?.title}`} />}
      />
    </Grid>
  ));

  return (
    <Container id="advantages" className="AdvantagesContainer">
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Typography className="AdvantagesTitle popins-bold" id="Advantaged">
          {" "}
          <Trans i18nKey="Advantages.Title" />
        </Typography>
        <Typography className="AdvantagesSmallTextContainerAdvantages AdvantagesSmallText">
          <Trans
            i18nKey="Advantages.smallText"
            components={{ span: <span className="AdvantagesSmallTextwhite" /> }}
          />
        </Typography>
      </Box>
      <Grid container>
      {AdvantagesListOfBoxs}
      </Grid>
      {/* <div className="Divider" /> */}
    </Container>
  );
}

export default Advantages;
