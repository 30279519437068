import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { SportsPlayTextBoxArray } from "../../assets/data";
import BoxWithImage from "../../Layout/componenets/BoxWithImage.js";
import "./SportsToPlay.scss";

function SportsToPlay() {


const SportsPlayListOfBoxs = SportsPlayTextBoxArray.map((SportsPlayTextBoxArray, index) => (
    <Grid item xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
      <BoxWithImage
        className="SportsToPlayBoxWithImage"
        key={index}
        src={SportsPlayTextBoxArray.src}
      text={<Trans i18nKey={`SportsPlay.${SportsPlayTextBoxArray?.text}`} />}
        title={<Trans i18nKey={`SportsPlay.${SportsPlayTextBoxArray?.title}`} />}
      />
    </Grid>
  ));

  return (
    <Container id="news" className="SportsToPlayContainer">
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Typography className="SportsToPlayTitle popins-bold" id="Advantaged">
          {" "}
          <Trans i18nKey="SportsPlay.Title" />
        </Typography>

        <Typography className="SportsToPlaysmallText SportsToPlaysmallTextContainer">
          <Trans
            i18nKey="SportsPlay.smallText"
            components={{
              span: <span className="SportsToPlaysmallTextwhite" />,
            }}
          />
        </Typography>

        <Grid container>{SportsPlayListOfBoxs}</Grid>
      </Box>
    </Container>
  );
}

export default SportsToPlay;
