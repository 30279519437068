import React, { useEffect, useState } from "react";
import "./App.scss";
import Casino from "./Layout/Casino/Casino";
import ContactUs from "./Layout/ContactUs/ContactUs";
import Footer from "./Layout/Footer/Footer";
import SportsToPlay from "./Layout/SportsToPlay/SportsToPlay";
import StartTheRace from "./Layout/StartTheRace/StartTheRace";
import Advantages from "./Layout/advantages/Advantages";
import Events from "./Layout/Events/Events";
import Testimonials from "./Layout/Testimonials/Testimonials";
import BecomeApartner from "./Layout/becomeApartner/BecomeApartner";
import LogoSlider from "./Layout/logosSlider/LogoSlider";
import NavBar from "./Layout/navBar/NavBar";
import TermsAndConditions from "./Layout/TermsAndConditions/TermsAndConditions";
import { useTranslation } from "react-i18next";

function App() {
  const {  i18n } = useTranslation();
  const [showTerms, setShowTerms] = useState(false);


  const [removeTermsButton, setRemoveTerms] = useState("block");
  const [showTermsButton, setShowTermsButton] = useState("none");
  
  const handleTextDownClickremoveButton = () => {
    setRemoveTerms("none");
    setShowTermsButton("block ");
  };

  const handleTextDownClickreturnButton = () => {
    setRemoveTerms("block");
    setShowTermsButton("none ");
  };

  useEffect(() => {
    i18n.changeLanguage("EN");
  }, [showTerms,i18n]);

  const handleTextDownClickShowFooter = () => {
    setShowTerms(true);
 
  };

  const handleTextDownClickShowCode = () => {
    setShowTerms(false);
 
  };

  return (
    <>
      <NavBar 
      handleTextDownClickShowCode={handleTextDownClickShowCode}
      handleTextDownClickreturnButton={handleTextDownClickreturnButton}
      />

      {showTerms ? (
        <TermsAndConditions />
      ) : (
        <>
          <StartTheRace />
          <LogoSlider />
          <BecomeApartner />
          <Casino />
          <SportsToPlay />
          <Advantages />
          <Events/>
          <Testimonials/>
          <ContactUs  
        handleTextDownClickShowFooter={handleTextDownClickShowFooter}
        handleTextDownClickremoveButton={handleTextDownClickremoveButton}
        />
        </>
      )}

      <Footer
        showTerms={showTerms}
        handleTextDownClickShowFooter={handleTextDownClickShowFooter}
        handleTextDownClickShowCode={handleTextDownClickShowCode}

        handleTextDownClickremoveButton={handleTextDownClickremoveButton}
        handleTextDownClickreturnButton={handleTextDownClickreturnButton}
        showTermsButton={showTermsButton}
        removeTermsButton={removeTermsButton}
      />
    </>
  );
}

export default App;