import axios from "axios";
export default function http() {
    const token = localStorage.getItem(
        process.env.TOKEN_NAME || "NABAFFILIATEStokenFromStorageToAuthenticateUserOnAdmin",
    );

    const connection = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });

    return connection;
}


