import React from "react";
import "./LogoSlider.scss";
import Marquee from "react-fast-marquee";
import { Box } from "@mui/material";
import { SliderList1, SliderList2 } from "../../assets/data";
function LogoSlider() {
  return (
    <Box className="LogoSliderWrapper">
      <Marquee className="MarqStyle1" speed="30" autoFill>
        {SliderList1}
      </Marquee>

      <Marquee speed="50" direction="right" className="MarqStyle2"  autoFill>
        {SliderList2}
      </Marquee>
    </Box>
  );
}

export default LogoSlider;
