import { Box, Container, Typography } from "@mui/material";
import React from 'react';
import { TermsAndConditionsParagraphArrTitle, TermsAndConditionsParagraphArrTitlePoints, subTitlesAfterAff } from "../../assets/data";
import './TermsAndConditions.scss';
import TermsAndConditionsDefinition from "./TermsAndConditionsDefinition";
import TermsAndConditionsParagraph from './TermsAndConditionsParagraph';


function TermsAndConditions() {




  



  return (
   <Container id="Terms" className="TermsAndConditionsContainer">
<Box className="TermsAndConditionsBox">
   <Typography className="TermsAndConditionsBigTitle popins-bold"> Terms &<span className="popins-bold" style={{ color: 'white' }}> Conditions</span>  </Typography>


   {TermsAndConditionsParagraphArrTitlePoints.map((item, index) => (
  <TermsAndConditionsDefinition
    key={index}
    Title={item.Title}
    subTitles={item.subTitles}
   
  />

  
))}


{subTitlesAfterAff.map((item) =>(



<Typography  className='TermsAndConditionsParagraphParagraphe'> <span style={{color:'white'}}> {item.title} </span>  {item.text}</Typography>



))}


  


   
   {TermsAndConditionsParagraphArrTitle.map((item, index) => (
  <TermsAndConditionsParagraph
    key={index}
    Title={item.Title}
    subTitles={item.subTitles}
    counter={index+1}
  />

))}

</Box>
   </Container>
  )
}

export default TermsAndConditions